import { useQuery } from 'react-query'
import { useSnackbar } from '../hooks/useSnackbar'
import { loadJSONFromState, saveJSONToState } from '../utils'

import * as api from '../services/api'

export function usePosts({ username, followeeID, onlyFollowees = false }) {
  const snackbar = useSnackbar()

  let key = ['postsData', username, followeeID, onlyFollowees]
  const { data, isLoading, isFetching, refetch } = useQuery(
    key,
    (params) => api.ListPosts({ username, followeeID, onlyFollowees }),
    {
      initialData: () => {
        return loadJSONFromState(key)
      },
      onSuccess: (dat) => {
        saveJSONToState(key, dat)
      },
      onError: (err) => {
        snackbar(err?.error?.message, 'error')
      },
    }
  )

  return { data, isLoading, isFetching, isFloading: isLoading || isFetching, refetch }
}
