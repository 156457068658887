import React from 'react'
import { makeStyles } from '@mui/styles'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Avatar from '../../../components/Avatar'

import UsernameRef from '../../../components/UsernameRef'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: `${theme.spacing()}px 0`,
    margin: `0px`,
  },
}))

const User = ({ user }) => {
  const classes = useStyles()
  return (
    <Grid item p={1} xs={12} sm={6} lg={4}>
      <Paper elevation={3}>
        <ListItem className={classes.listItem} key={user.userID}>
          <Avatar alt={`${user?.first} ${user?.last}`} src={user?.thumbnailURL ? user?.thumbnailURL : null} />
          <ListItemText
            primary={<UsernameRef username={user.username} />}
            secondary={
              <>
                {user.first} {user.last} {user.privateProfile ? 'Private' : 'Public'}{' '}
                {user.autoApproveFollowers ? 'Auto Approve' : ''}
              </>
            }
          />
        </ListItem>
      </Paper>
    </Grid>
  )
}

export default User
