import React, { useState } from 'react'

import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'

import { useSnackbar } from '../../hooks/useSnackbar'
import Post from './components/Post'
import Checkbox from '../../components/Checkbox'
import { usePosts } from '../../hooks/usePosts'

function PostsPage() {
  const snackbar = useSnackbar()
  const [onlyFollowees, setOnlyFollowees] = useState(false)

  const { data: postsQuery, isFloading } = usePosts({ onlyFollowees })

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Posts
      </Typography>
      {isFloading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      <Checkbox
        name="onlyFollowees"
        label="Only Posts From Followees"
        checked={onlyFollowees}
        onChange={() => setOnlyFollowees(!onlyFollowees)}
      />
      <Grid container padding={1} spacing={1}>
        <Grid item xs={12} sm={12}>
          <List disablePadding>
            <Grid item container key={0}>
              {postsQuery?.posts?.length === 0 ? (
                <ListItem key={1}>None</ListItem>
              ) : (
                postsQuery?.posts?.map((post) => (
                  <Grid item xs={12} md={6} p={1} key={post.id}>
                    <Post post={post} />
                  </Grid>
                ))
              )}
            </Grid>
          </List>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PostsPage
