import { useState } from 'react'
import React from 'react'
import Badge from '../../components/Badge'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '../../components/Checkbox'
import { useUsers } from '../../hooks/useUsers'
import { usePosts } from '../../hooks/usePosts'
import { useNews } from '../../hooks/useNews'
import { useLocalStorage } from '../../hooks/useLocalStorage'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { useCatFacts } from '../../hooks/useCatFacts'
import { useFollowers } from '../../hooks/useFollowers'
import { useFollowees } from '../../hooks/useFollowees'
import Avatar from '../../components/Avatar'
import { DeleteFollowee, UpdateFollower } from '../../services/api'
import { useAuth } from '../../services/authcontext'
import UsernameRef from '../../components/UsernameRef'
import { useSnackbar } from '../../hooks/useSnackbar'

import Post from './components/Post'
import Followee from './components/Followee'
import User from './components/User'
import Follower from './components/Follower'

const Home = (props) => {
  const classes = useStyles()
  const { authData } = useAuth()
  const [whichAccordion, setWhichAccordion] = useLocalStorage('whichAccordion', '')
  const snackbar = useSnackbar()
  const [onlyFollowees, setOnlyFollowees] = useState(false)

  const { data: usersQuery, isFloading: usersIsLoading } = useUsers()
  const { data: catFact, isFloading: catsIsLoading } = useCatFacts()
  const { data: postsQuery, isFloading: postsIsLoading } = usePosts({ onlyFollowees })
  const { data: followersQuery, isFloading: followersIsLoading, refetch: refetchFollowers } = useFollowers()
  const { data: followeesQuery, isFloading: followeesIsLoading, refetch: refetchFollowees } = useFollowees()
  const { data: newsQuery, isFloading: newsIsLoading, refetch: refetchNews } = useNews()

  const handleSetAccordion = (which) => {
    if (whichAccordion === which) {
      setWhichAccordion('')
    } else {
      setWhichAccordion(which)
    }
  }

  const handleUpdateFollower = (id, position, status) => {
    UpdateFollower({
      id: id,
      accepted: status,
    }).then(() => refetchFollowers())
  }
  return (
    <main className={classes.layout}>
      <Typography variant="h5" gutterBottom>
        Home
      </Typography>
      <Grid container>
        <Grid item p={1}>
          <Avatar
            alt={`${authData?.user?.first} ${authData?.user?.last}`}
            src={authData?.user?.thumbnailURL}
            sx={{ width: 250, height: 250 }}
          />
        </Grid>
        <Grid item>
          <Typography>
            {authData?.user?.first} {authData?.user?.last}
          </Typography>
          <Typography>
            <UsernameRef username={authData?.user?.username} />
          </Typography>
        </Grid>
        <Grid item xs={12} p={1}>
          <Accordion expanded={whichAccordion === 'followers'} onChange={() => handleSetAccordion('followers')}>
            <AccordionSummaryCustom>
              <Badge badgeContent={followersQuery?.followers?.length}>
                <Typography variant="h5">Followers</Typography>
              </Badge>
              {followersIsLoading && (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              )}
            </AccordionSummaryCustom>
            <AccordionDetails>
              <List disablePadding>
                {followersQuery?.followers?.length === 0 ? (
                  <p>None</p>
                ) : (
                  <Grid container spacing={2}>
                    {followersQuery?.followers?.map((follower, i) => (
                      <Follower follower={follower} key={follower.userID} i={i} />
                    ))}
                  </Grid>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} p={1}>
          <Accordion expanded={whichAccordion === 'followees'} onChange={() => handleSetAccordion('followees')}>
            <AccordionSummaryCustom>
              <Badge badgeContent={followeesQuery?.followers?.length}>
                <Typography variant="h5">Followees</Typography>
              </Badge>
              {followeesIsLoading && (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              )}
            </AccordionSummaryCustom>
            <AccordionDetails>
              <List disablePadding>
                <Grid item container>
                  {followeesQuery?.followers?.length === 0 ? (
                    <ListItem>None</ListItem>
                  ) : (
                    followeesQuery?.followers?.map((followee) => (
                      <Followee
                        followee={followee}
                        key={followee.id}
                        onClick={() => DeleteFollowee(followee.userID).then(() => refetchFollowees())}
                      />
                    ))
                  )}
                </Grid>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} p={1}>
          <Accordion expanded={whichAccordion === 'users'} onChange={() => handleSetAccordion('users')}>
            <AccordionSummaryCustom>
              <Badge badgeContent={usersQuery?.users?.length}>
                <Typography variant="h5">Users</Typography>
              </Badge>
              {usersIsLoading && (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              )}
            </AccordionSummaryCustom>
            <AccordionDetails>
              <List disablePadding>
                <Grid container padding={1}>
                  {usersQuery?.users?.length === 0 ? (
                    <ListItem>None</ListItem>
                  ) : (
                    usersQuery?.users?.map((user, i) => <User user={user} key={user.id} />)
                  )}
                </Grid>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} p={1}>
          <Accordion expanded={whichAccordion === 'posts'} onChange={() => handleSetAccordion('posts')}>
            <AccordionSummaryCustom>
              <Badge badgeContent={postsQuery?.posts?.length}>
                <Typography variant="h5">Posts</Typography>
              </Badge>
              {postsIsLoading && (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              )}
            </AccordionSummaryCustom>
            <AccordionDetails>
              <Grid item>
                <Checkbox
                  name="onlyFollowees"
                  label="Only Posts From Followees"
                  checked={onlyFollowees}
                  onChange={() => setOnlyFollowees(!onlyFollowees)}
                />
              </Grid>
              <List disablePadding>
                <Grid container spacing={2}>
                  {postsQuery?.posts?.length === 0 ? (
                    <ListItem>None</ListItem>
                  ) : (
                    postsQuery?.posts?.map((post) => (
                      <Grid item xs={12} md={6} p={1} key={post.postID}>
                        <Post post={post} key={post.postID} />
                      </Grid>
                    ))
                  )}
                </Grid>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} p={1}>
          <Accordion expanded={whichAccordion === 'news'} onChange={() => handleSetAccordion('news')}>
            <AccordionSummaryCustom>
              <Badge badgeContent={newsQuery?.news?.length}>
                <Typography variant="h5">News</Typography>
              </Badge>
              {newsIsLoading && (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              )}
            </AccordionSummaryCustom>
            <AccordionDetails>
              <Grid container spacing={2}>
                {postsQuery?.posts?.length === 0 ? (
                  <ListItem>None</ListItem>
                ) : (
                  newsQuery?.news?.map((news) => (
                    <Grid item xs={12} sm={6} md={4} p={1} key={news.uuid}>
                      <MediaCard news={news} />
                    </Grid>
                  ))
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Typography>{catFact?.fact}</Typography>
      </Grid>
    </main>
  )
}

function MediaCard({ news }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia sx={{ height: 140 }} image={news?.image_url} title="green iguana" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {news?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {news?.description}
        </Typography>
        <Typography variant="body3" color="text.secondary">
          {news?.published_at}
        </Typography>
      </CardContent>
      <CardActions>
        <a href={news?.url}>{news?.url}</a>
      </CardActions>
    </Card>
  )
}

const AccordionSummaryCustom = (props) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      style={{ margin: 0 }}
      {...props}
    ></AccordionSummary>
  )
}

export default Home

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: `${theme.spacing()}px 0`,
    margin: `0px`,
  },
}))
