import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import { useQuery } from 'react-query'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Paper from '@mui/material/Paper'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ListFollowers, DeleteFollower, UpdateFollower } from '../../../services/api'
import UsernameRef from '../../../components/UsernameRef'
import { useSnackbar } from '../../../hooks/useSnackbar'
import Menu from '@mui/material/Menu'

const Follower = ({ follower, i }) => {
  const [anchorEl, setAnchorEl] = useState()
  const snackbar = useSnackbar()
  const [value, setValue] = useState(follower.accepted)
  const open = Boolean(anchorEl)
  const [selectedIndex, setSelectedIndex] = React.useState(1)

  const {
    data: followersQuery,
    isLoading: followersIsLoading,
    refetch: refetchFollowers,
  } = useQuery('followersData', ListFollowers, {
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
  })

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, status) => {
    if (status === 'delete') {
      DeleteFollower(follower.userID)
        .then(() => {
          refetchFollowers()
        })
        .catch((err) => snackbar(err?.error?.message, 'error'))
    } else {
      UpdateFollower({
        id: follower.userID,
        accepted: status,
      })
        .then(() => refetchFollowers())
        .catch((err) => snackbar(err?.error?.message, 'error'))
    }
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (status) => {
    setValue(status)
  }
  const handleUpdateFollower = (id, position, status) => {}

  return (
    <Grid item p={1} xs={12} sm={6} lg={4} key={follower.userID}>
      <Paper elevation={3}>
        <ListItem key={follower.userID}>
          <ListItemText
            primary={
              <>
                {follower.nameFirst} {follower.nameLast} <UsernameRef username={follower.username} />
              </>
            }
          />
          <IconButton onClick={handleClickListItem} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
            {follower.accepted ? (
              <CheckBoxIcon />
            ) : follower.accepted === null || follower.accepted === undefined ? (
              <CheckBoxOutlineBlankIcon />
            ) : (
              <DisabledByDefaultIcon />
            )}
          </IconButton>
          <Menu onChange={handleChange} open={open} onClose={handleClose} anchorEl={anchorEl}>
            <MenuItem value="true" onClick={(event) => handleMenuItemClick(event, true)}>
              <ListItemIcon>
                <CheckBoxIcon /> Accept
              </ListItemIcon>
            </MenuItem>
            <MenuItem value={null} onClick={(event) => handleMenuItemClick(event, undefined)}>
              <ListItemIcon>
                <CheckBoxOutlineBlankIcon /> Delay
              </ListItemIcon>
            </MenuItem>
            <MenuItem value="false" onClick={(event) => handleMenuItemClick(event, false)}>
              <ListItemIcon>
                <DisabledByDefaultIcon /> Deny
              </ListItemIcon>
            </MenuItem>
            <MenuItem value="false" onClick={(event) => handleMenuItemClick(event, 'delete')}>
              <ListItemIcon>
                <DeleteForeverIcon /> Delete
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </ListItem>
      </Paper>
    </Grid>
  )
}

export default Follower
