import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

export const TextInput = (props) => {
  return <TextField required {...props} fullWidth />
}

export const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      required
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disableElevation
              disableRipple
              onClick={() => {
                setShowPassword((showPassword) => !showPassword)
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  )
}
