import { DeleteFollowee } from '../../services/api'
import React from 'react'

import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Followee from './components/Followee'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useFollowees } from '../../hooks/useFollowees'

function UsersPage() {
  const snackbar = useSnackbar()

  const { data: followeesQuery, isLoading: followeesIsLoading, refetch: refetchFollowees } = useFollowees()

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Followees
      </Typography>
      {followeesIsLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container padding={1} spacing={1}>
          <Grid item xs={12} sm={12}>
            <List disablePadding>
              <Grid item container>
                {followeesQuery?.followers?.length === 0 ? (
                  <ListItem>None</ListItem>
                ) : (
                  followeesQuery?.followers?.map((followee, i) => (
                    <Followee
                      key={followee.id}
                      followee={followee}
                      onClick={() => DeleteFollowee(followee.userID).then(() => refetchFollowees())}
                    />
                  ))
                )}
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}></Grid>
              </Grid>
            </List>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default UsersPage
