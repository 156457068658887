import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useParams } from 'react-router-dom'
import { ResetPassword } from '../../services/api'
import Button from '../../components/Button'
import { useMutation } from 'react-query'
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

function ResetPasswordPage() {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const navigate = useNavigate()
  let { verificationCode } = useParams()
  const snackbar = useSnackbar()

  const handleSubmit = () => {
    if (password === '') {
      snackbar('password is required', 'error')
      return
    }
    if (confirmPassword !== password) {
      snackbar("confirmed password doesn't match password", 'error')
      return
    }
    ResetPasswordQ({ verificationCode, newPassword: password })
  }

  const { mutate: ResetPasswordQ, isLoading } = useMutation(
    () => ResetPassword({ verificationCode, newPassword: password }),
    {
      onSuccess: () => {
        snackbar('Password Reset! login to continue')
        navigate('/')
      },
      onError: (error) => {
        snackbar(error?.error?.message, 'error')
        navigate('/')
      },
    }
  )

  return (
    <main>
      <Typography variant="h6" gutterBottom>
        Reset Your Password
      </Typography>
      <Grid container>
        <Grid p={2} item sm={6} xs={12}>
          <TextField
            required
            label="Password"
            id="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword((showPassword) => !showPassword)
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? 'text' : 'password'}
          />
        </Grid>
        <Grid p={2} item sm={6} xs={12}>
          <TextField
            required
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)
                    }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showConfirmPassword ? 'text' : 'password'}
          />
        </Grid>
        <Grid p={2} item>
          <Button disabled={isLoading} onClick={handleSubmit}>
            {'Submit'}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {isLoading && <CircularProgress />}
        </Grid>
        <Grid item p={2}>
          <Button onClick={() => navigate('/')}>Return to login page</Button>
        </Grid>
      </Grid>
    </main>
  )
}

export default ResetPasswordPage
