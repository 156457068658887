import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Button from '@mui/material/Button'

import { useSnackbar } from '../../../hooks/useSnackbar'

import { DeletePhoto } from '../../../services/api'
import IconButton from '@mui/material/IconButton'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CardMedia from '@mui/material/CardMedia'
import Card from '@mui/material/Card'
import Dialog from '@mui/material/Dialog'
import { useAuth } from '../../../services/authcontext'

const Photo = ({ photo, refetch }) => {
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { authData } = useAuth()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    deletePhotoQ(photo.id)
  }

  const handlePressPhoto = () => {}
  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const {
    mutate: deletePhotoQ,
    isLoading: deleteIsLoading,
    error: deleteError,
  } = useMutation((id) => DeletePhoto(id), {
    onSuccess: () => {
      snackbar('Photo deleted successfully!')
      refetch()
      //   queryClient.invalidateQueries({ queryKey: ['photos', photo.userID] })
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })
  return (
    <>
      {false && (
        <Card key={photo.id}>
          <CardMedia>
            <Photo photo={photo} />
          </CardMedia>
        </Card>
      )}
      <Button onClick={() => setDialogOpen(true)}>
        <img src={photo.url} style={{ objectFit: 'stretch', width: '100%' }} alt="none" />
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={(event) => handleDelete(event, 'delete')}>
          <ListItemIcon>
            <DeleteForeverIcon /> Delete
          </ListItemIcon>
        </MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <>
          <img style={{ width: 'auto', height: '100%', position: 'relative' }} alt="alt text" src={photo.url} />
          {photo.userID === authData.user.userID && (
            <div style={{ position: 'absolute', zIndex: 1000 }}>
              <IconButton
                sx={{ backgroundColor: 'black' }}
                color="primary"
                disableElevation
                disableRipple
                zlevel={100000}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </div>
          )}
        </>
        {photo.created}
      </Dialog>
    </>
  )
}

export default Photo
