import './App.css'
import Router from './pages/Router'
import { AuthProvider } from './services/authcontext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from './hooks/useSnackbar'
import { QueryClient, QueryClientProvider } from 'react-query'

const theme = createTheme({})
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
})

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}
export default App
