import { useTheme } from '@mui/styles' // or @mui/joy/styles
import useMediaQuery from '@mui/material/useMediaQuery'
import { Breakpoint } from '@mui/system'

// useBreakpoint returns the current breakpoint: "xs", "sm", "md", "lg"
export const useBreakpoint = (): Breakpoint => {
    const theme = useTheme()
    const keys = [...theme.breakpoints.keys]

    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key))
            return matches ? key : output
        }, null) ?? 'xs'
    )
}

export default useBreakpoint
