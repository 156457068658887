import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '../../components/Button'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useAuth } from '../../services/authcontext'
import { RequestPasswordReset } from '../../services/api'
import { useSnackbar } from '../../hooks/useSnackbar'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
// import FacebookLogin from 'react-facebook-login'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export function LoginPage() {
  const [photo, setPhoto] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState()
  const auth = useAuth()
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState()
  const [hasRequestedPasswordReset, setHasRequestedPasswordReset] = useState()

  const handleSubmit = async () => {
    setLoading(true)
    await auth.login({ email, password }).catch((r) => snackbar(r?.error?.message, 'error'))
    setLoading(false)
  }

  const handleResetPasswordRequest = async () => {
    if (email === '') {
      snackbar('Please enter an email to request a password reset.', 'error')
      return
    }
    RequestPasswordReset(email)
      .then((res) => {
        snackbar('Password reset request sent! Check your email for a link to reset your password.')
        setHasRequestedPasswordReset(true)
      })
      .catch((err) => snackbar(err?.error?.message, 'error'))
  }

  // const handleFacebook = async (response) => {
  //   console.log(response)
  //   setPhoto(`https://graph.facebook.com/${response.id}/picture?type=large`)
  // }

  // console.log({ photo })
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Login
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} p={2}>
          <TextField
            required
            id="email"
            name="email"
            label="Username or Email"
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} p={2}>
          <TextField
            required
            label="Password"
            id="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword((showPassword) => !showPassword)
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? 'text' : 'password'}
          />
        </Grid>
      </Grid>
      <Grid item container p={1} xs={12}>
        <Grid p={2} item>
          <Button onClick={handleSubmit} className={classes.button}>
            {'Submit'}
          </Button>
        </Grid>
        <Grid p={2} item>
          <Button
            variant="text"
            disabled={hasRequestedPasswordReset}
            disableElevation
            onClick={handleResetPasswordRequest}
          >
            {hasRequestedPasswordReset
              ? 'Password Reset Request Sent! Check your email'
              : 'Forgot your password? Request a password reset'}
          </Button>
        </Grid>
      </Grid>
      {/* <Grid p={2}>
        <FacebookLogin
          size="small"
          appId="1083675142674553"
          autoLoad={true}
          fields="name,email,picture"
          callback={handleFacebook}
        />
        <img src={photo} />
      </Grid> */}
      <Grid p={2}>{loading && <CircularProgress />}</Grid>
    </>
  )
}

export default LoginPage
