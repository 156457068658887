import { ListUsers } from '../../services/api'
import React from 'react'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import { useQuery } from 'react-query'
import Typography from '@mui/material/Typography'

import { useSnackbar } from '../../hooks/useSnackbar'
import User from './components/User'

function UsersPage() {
  const snackbar = useSnackbar()

  const {
    data: usersQuery,
    isLoading: usersIsLoading,
    refetch: refetchUsers,
  } = useQuery('usersData', ListUsers, {
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
  })

  return (
    <React.Fragment>
      <Grid container>
        <Typography variant="h6" gutterBottom>
          Users
        </Typography>
        <IconButton onClick={() => refetchUsers()}>
          <RefreshIcon />
        </IconButton>
      </Grid>
      {usersIsLoading ? (
        <Grid item xs={12} container justify="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container padding={1}>
          <Grid item xs={12} sm={12}>
            <List disablePadding>
              <Grid item container>
                {usersQuery?.users?.length === 0 ? (
                  <ListItem>None</ListItem>
                ) : (
                  usersQuery?.users?.map((user, i) => <User user={user} key={user.userID} />)
                )}
              </Grid>
            </List>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default UsersPage
