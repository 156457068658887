import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useParams } from 'react-router-dom'
import { VerifyAccount } from '../../services/api'
import Button from '../../components/Button'
import { useMutation } from 'react-query'
import CircularProgress from '@mui/material/CircularProgress'

function VerifyPage() {
  const navigate = useNavigate()
  let { verificationCode } = useParams()
  const snackbar = useSnackbar()

  const { mutate: VerifyAccountQ, isLoading } = useMutation(() => VerifyAccount(verificationCode), {
    onSuccess: () => {
      snackbar('Account verified! login to continue')
      navigate('/')
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
      navigate('/')
    },
  })

  useEffect(() => {
    // Wait for a while to avoid double-mutation on dev + StrictMode.
    // See: https://github.com/TanStack/query/issues/5341
    const timeout = setTimeout(() => VerifyAccountQ(), 1000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <main>
      <Typography variant="h6" gutterBottom>
        Verifying your Account...
      </Typography>
      <Grid item xs={12}>
        {isLoading && <CircularProgress />}
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => navigate('/')}>Already set up? Click here to go to the login page.</Button>
      </Grid>
    </main>
  )
}

export default VerifyPage
