import React, { createContext } from 'react'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const SnackbarContext = createContext()

export function useSnackbar() {
  return React.useContext(SnackbarContext)
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const SnackbarProvider = ({ children }, ...props) => {
  const [snackbarState, setSnackbarState] = React.useState({ open: false, message: '' })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarState((state) => ({ ...state, open: false }))
  }

  const sendSnackbarMessage = (message, severity) => {
    setSnackbarState({
      open: true,
      message: message ? message : 'An unknown error occured. Please try again later',
      severity,
    })
  }
  return (
    <SnackbarContext.Provider value={sendSnackbarMessage} {...props}>
      {children}
      <Snackbar open={snackbarState.open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}
