import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'
import { SearchUsers } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from '../../hooks/useSnackbar'

export function SearchBar() {
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const [searchResults, setSearchResults] = useState([])
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchValue(inputValue), 500)
    return () => clearTimeout(timeOutId)
  }, [inputValue])

  useEffect(() => {
    if (searchValue !== '') {
      SearchUsers(searchValue)
        .then((res) => setSearchResults(res.user.map((u) => `${u.first} ${u.last} ${u.username}`)))
        .catch((res) => snackbar(res?.error?.message, 'error'))
    } else if (searchValue === inputValue) {
    } else {
      setSearchResults([])
    }
  }, [searchValue])

  return (
    <div>
      <br />
      <Autocomplete
        freeSolo
        disableClearable
        value={inputValue}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          navigate(`/users/${newValue.split(' ')[2]}`)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        options={searchResults}
        sx={{ width: 200 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Search" />}
      />
    </div>
  )
}

export default SearchBar
