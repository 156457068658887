import React, { useState } from 'react'
import PostBox from '../components/PostBox'
import { useSnackbar } from '../hooks/useSnackbar'
import { ContactUs } from '../services/api'
import { useNavigate } from 'react-router-dom'

const ContactUsPage = () => {
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const contactUs = (message) => {
    if (message === '') {
      return
    }
    setIsLoading(true)
    ContactUs(message)
      .then(() => {
        setIsLoading(false)
        snackbar('Message sent successfully!')
        navigate('/')
      })
      .catch((err) => snackbar(err?.error?.message, 'error'))
  }
  return <>{<PostBox label={'Contact Us'} onPost={(message) => contactUs(message)} />}</>
}

export default ContactUsPage
