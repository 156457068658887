import React, { useState } from 'react'
import { useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '@mui/material/Container'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LoginIcon from '@mui/icons-material/Login'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { CatFact } from '../services/api'
import { useSnackbar } from '../hooks/useSnackbar'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'

import { SearchBar } from './components/SearchBar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InfoIcon from '@mui/icons-material/Info'
import { Outlet } from 'react-router-dom'
import PlagiarismIcon from '@mui/icons-material/Plagiarism'

export default function UnauthorizedLayout() {
  const navigate = useNavigate()
  const theme = useTheme()
  const lessThanSM = useMediaQuery(theme.breakpoints.down('sm'))
  const snackbar = useSnackbar()
  const [drawerLeft, setDrawerLeft] = useState(false)

  const [value, setValue] = React.useState(0)
  const classes = useStyles()
  const { data: catFact, isLoading: catsIsLoading } = useQuery('catFact', CatFact, {
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
  })
  const list = (anchor) => (
    <Box sx={{ width: 250 }} role="presentation">
      <CssBaseline />
      <List>
        <ListItem>
          <SearchBar />
        </ListItem>
        {[
          { icon: <LoginIcon />, to: '/', label: 'Login' },
          { icon: <PersonAddIcon />, to: '/create_account', label: 'Create Account' },
          { icon: <PlagiarismIcon />, to: '/privacy_policy', label: 'Privacy Policy' },
          { icon: <InfoIcon />, to: '/information', label: 'Information' },
        ].map((dets) => (
          <ListItem key={dets.label} disablePadding>
            <ListItemButton onClick={() => navigate(dets.to) || setDrawerLeft(false)}>
              <ListItemIcon>{dets.icon}</ListItemIcon>
              <ListItemText primary={dets.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <Container maxWidth={false} className={classes.root} disableGutters={lessThanSM}>
      <Drawer anchor={'left'} open={drawerLeft} onClose={() => setDrawerLeft(false)}>
        {list()}
      </Drawer>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setDrawerLeft(true)} edge="start">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            TylorCorp
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid item>
        <AppBar position="absolute" />
      </Grid>
      <Grid item>
        <Container maxWidth={'lg'} disableGutters={lessThanSM}>
          <Paper elevation={3} className={classes.paper}>
            {<Outlet />}
          </Paper>
        </Container>
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(5),
    height: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + theme.spacing(1))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  root: {
    padding: 0,
    margin: 0,
    height: '100vh',
  },
}))
