import { useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

export function PostBox({ onPost, isLoading, label = 'New Post' }) {
  const [postText, setPostText] = useState()

  return (
    <div>
      <TextField
        multiline
        variant="outlined"
        label={label}
        minRows={10}
        maxRows={5}
        fullWidth
        value={postText}
        onChange={(e) => setPostText(e.target.value)}
      />
      <Button disabled={isLoading} onClick={() => onPost(postText) || setPostText('')}>
        Submit
      </Button>
    </div>
  )
}

export default PostBox
