import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { usePhotos } from '../../hooks/usePhotos'
import { useSnackbar } from '../../hooks/useSnackbar'
import { AddPhoto } from '../../services/api'
import useBreakpoint from '../../hooks/useBreakpoint'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Photo from './components/Photo'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../services/authcontext'

function PhotosPage() {
  const snackbar = useSnackbar()
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const breakpoint = useBreakpoint()
  const params = useParams()
  const { authData } = useAuth()

  const { data: photosQuery, isFloading: photosIsLoading, refetch } = usePhotos({ username: params.username })

  const addPhoto = (photo) => {
    setIsLoading(true)
    try {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const srcData = fileReader.result
        AddPhoto(srcData) // contains filereader metadata, which is fine, we just need to read it on the front end.
          .then((res) => {
            snackbar(`photo uploaded successfully!`)
            setIsLoading(false)
            refetch()
          })
          .catch((res) => {
            snackbar(`failed to add photo: ${res.error.message}`, 'error')
            setIsLoading(false)
          })
      }
      fileReader.readAsDataURL(photo)
    } catch (error) {
      setIsLoading(false)
      snackbar('Error reading file. Please try again later', 'error')
    }
  }
  const cols = { xs: 1, sm: 2, md: 4, lg: 6, xl: 6 }[breakpoint]
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Photos
      </Typography>
      {(isLoading || photosIsLoading) && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {authData.user.username === params.username && (
        <Button
          disableElevation
          disableRipple
          disabled={isLoading || photosIsLoading}
          onClick={() => inputRef.current.click()}
        >
          Add a Photo{' '}
        </Button>
      )}
      <input
        type="file"
        name="profileImage"
        accept="image/*"
        className="local"
        ref={inputRef}
        onChange={(event) => {
          event.target.files[0] && addPhoto(event.target.files[0])
        }}
        hidden
      />
      <ImageList sx={{ width: '100%' }} cols={cols}>
        {photosQuery?.photos?.length === 0 ? (
          <ImageListItem>None</ImageListItem>
        ) : (
          photosQuery?.photos?.map((photo) => (
            <ImageListItem key={photo.id}>
              <Photo photo={photo} refetch={refetch} />
            </ImageListItem>
          ))
        )}
      </ImageList>
    </React.Fragment>
  )
}

export default PhotosPage
