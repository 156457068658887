import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton'
import UsernameRef from '../../../components/UsernameRef'

import { makeStyles } from '@mui/styles'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: `${theme.spacing()}px 0`,
    margin: `0px`,
  },
}))

const Followee = ({ followee, onClick }) => {
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, status) => onClick()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6} md={4} p={1} key={followee.userID}>
      <Paper elevation={3}>
        <ListItem className={classes.listItem} key={followee.userID}>
          <ListItemText primary={<UsernameRef username={followee.username} />} />
          <IconButton onClick={handleOpen}>
            <DeleteForeverIcon />
          </IconButton>
          <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
            <MenuItem value={null} onClick={(event) => handleMenuItemClick(event, undefined)}>
              <ListItemIcon>
                <DeleteForeverIcon /> Delete
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </ListItem>
      </Paper>
    </Grid>
  )
}

export default Followee
