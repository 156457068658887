import React, { useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '../../components/Button'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { GetMe, EditUser, UpdateThumbnail, DeleteAccount } from '../../services/api'
import Checkbox from '../../components/Checkbox'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useAuth } from '../../services/authcontext'
import CircularProgress from '@mui/material/CircularProgress'
import { PasswordInput } from '../../components/Input'
import Popover from '@mui/material/Popover'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(),
  },
}))

function SettingsPage() {
  const { authData, setAuthDataFn, logout } = useAuth()
  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [deleteAccountPassword, setDeleteAccountPassword] = useState('')
  const inputRef = useRef(null)
  const [editUser, setEditUser] = useState(false)

  const LoadThumbnail = (thumbnail) => {
    setIsLoading(true)
    try {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const srcData = fileReader.result
        UpdateThumbnail(srcData) // contains filereader metadata, which is fine, we just need to read it on the front end.
          .then((res) => {
            ;(res?.thumbnailURL && snackbar('Thumbnail updated successfully!')) ||
              setAuthDataFn({
                ...authData,
                user: { ...authData.user, thumbnailURL: res.thumbnailURL },
              }) // This works, but state update should maybe be centralized in a reducer.
            setIsLoading(false)
          })
          .catch((res) => {
            snackbar(`failed to update thumbnail: ${res.error.message}`, 'error')
            setIsLoading(false)
          })
      }
      fileReader.readAsDataURL(thumbnail)
    } catch (error) {
      setIsLoading(false)
      snackbar('Error reading file. Please try again later', 'error')
    }
  }

  const [userInfo, setUserInfo] = useState({
    email: '',
    username: '',
    first: '',
    last: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    privateProfile: false,
    autoApproveFollowers: false,
    sendNotificationEmails: false,
  })
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    const fetchData = async () => {
      let tempUser = await GetMe().catch((res) => snackbar(res?.error?.message, 'error'))
      tempUser?.user && setUserInfo(tempUser.user)
    }
    fetchData()
  }, [])

  const handleSubmit = async () => {
    setIsLoading(true)
    // Input validation
    if (userInfo.newPassword !== userInfo.confirmNewPassword) {
      snackbar("new passwords don't match. Please try again.", 'error')
      return
    }
    if (userInfo.newPassword === '') {
      userInfo.newPassword = undefined
    }
    const input = userInfo
    EditUser(input)
      .then((res) => {
        snackbar('Information updated successfully!') ||
          setEditUser(false) ||
          setUserInfo((state) => ({ ...state, password: '', newPassword: '', confirmNewPassword: '' }))
        setIsLoading(false)
      })
      .catch((res) => {
        snackbar(res?.error?.message, 'error')
        setIsLoading(false)
      })
  }

  const handleDelete = async (event) => {
    setAnchorEl(event.currentTarget)
  }

  const deleteAccount = async () => {
    setIsLoading(true)
    // Input validation
    if (deleteAccountPassword === '') {
      snackbar('Password is required to delete account', 'error')
      return
    }

    DeleteAccount(deleteAccountPassword)
      .then((res) => {
        snackbar('Account Deleted Successfully!')
        setIsLoading(false)
        logout()
      })
      .catch((res) => {
        snackbar(res?.error?.message, 'error')
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        User Information
      </Typography>
      {isLoading && <CircularProgress />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            disabled={true}
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            value={userInfo.email || ''}
            onChange={(e) => setUserInfo((state) => ({ ...state, email: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            disabled={!editUser}
            id="username"
            name="username"
            label="Username"
            fullWidth
            value={userInfo.username || ''}
            onChange={(e) => setUserInfo((state) => ({ ...state, username: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editUser}
            required
            id="first"
            name="first"
            label="First Name"
            fullWidth
            value={userInfo.first || ''}
            onChange={(e) => setUserInfo((state) => ({ ...state, first: e.target.value }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={!editUser}
            required
            id="last"
            value={userInfo.last || ''}
            name="last"
            label="Last Name"
            fullWidth
            onChange={(e) => setUserInfo((state) => ({ ...state, last: e.target.value }))}
          />
        </Grid>
        {editUser && (
          <>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                disabled={!editUser}
                label="Current Password"
                value={userInfo.password || ''}
                fullWidth
                onChange={(e) =>
                  setUserInfo((state) => ({
                    ...state,
                    password: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                disabled={!editUser}
                label="New Password"
                value={userInfo.newPassword || ''}
                fullWidth
                onChange={(e) =>
                  setUserInfo((state) => ({
                    ...state,
                    newPassword: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PasswordInput
                disabled={!editUser}
                label="Confirm New Password"
                fullWidth
                value={userInfo.confirmNewPassword || ''}
                onChange={(e) =>
                  setUserInfo((state) => ({
                    ...state,
                    confirmNewPassword: e.target.value,
                  }))
                }
              />
            </Grid>
          </>
        )}
        <Grid container p={2}>
          <Grid item xs={12}>
            <Checkbox
              disabled={!editUser}
              name="MFA"
              checked={userInfo.mfa_enabled || false}
              onChange={(e) =>
                setUserInfo((state) => ({
                  ...state,
                  mfa_enabled: e.target.value,
                }))
              }
              label="Multi-Factor Authorization enabled"
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={!editUser}
              label="Private profile"
              name="privateProfile"
              checked={userInfo.privateProfile || false}
              onChange={(e) =>
                setUserInfo((state) => ({
                  ...state,
                  privateProfile: e.target.checked,
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={!editUser}
              label="Automatically approve followers"
              name="autoApproveFollowers"
              checked={userInfo.autoApproveFollowers || false}
              onChange={(e) =>
                setUserInfo((state) => ({
                  ...state,
                  autoApproveFollowers: e.target.checked,
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={!editUser}
              label="Send notification emails"
              name="sendNotificationEmails"
              checked={userInfo.sendNotificationEmails || false}
              onChange={(e) =>
                setUserInfo((state) => ({
                  ...state,
                  sendNotificationEmails: e.target.checked,
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid container item xs={12} sm={6} justify="center" alignItems="center">
                <Button onClick={editUser ? () => handleSubmit() : () => setEditUser(true)} className={classes.button}>
                  {editUser ? 'Submit update' : 'Edit User'}
                </Button>
              </Grid>
              <Grid container item justify="center" alignItems="center" xs={12} sm={6}>
                <Button disabled={isLoading} color="primary" onClick={() => inputRef.current.click()}>
                  Update Profile Picture
                </Button>
              </Grid>
              <Grid container item justify="center" alignItems="center" xs={12} sm={6}>
                <Button disabled={isLoading} color="error" onClick={handleDelete}>
                  Delete Account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <input
        type="file"
        name="profileImage"
        accept="image/*"
        className="local"
        ref={inputRef}
        onChange={(event) => {
          event.target.files[0] && LoadThumbnail(event.target.files[0])
        }}
        hidden
      />
      <Popover
        id={id}
        p={2}
        m={2}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid p={1} spacing={1} container maxWidth={500}>
          <Grid item xs={12}>
            <Typography>
              This will permanently delete your account and all information associated with it. It can not be recovered.
              Are you sure?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              label="Current Password"
              value={deleteAccountPassword || ''}
              fullWidth
              onChange={(e) => setDeleteAccountPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={isLoading} color="error" onClick={deleteAccount}>
              Delete My Account
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  )
}

export default SettingsPage
