import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'

const InformationPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <Grid container spacing={1} align="center" justify="center">
        <Grid item xs={12}>
          <h1>Welcome to TylorCorp. Here&apos;s some technologies in use on this site:</h1>
        </Grid>
        <Grid item xs={12}>
          <a href="https://redis.com/"> Redis</a> for short-term caching
        </Grid>
        <Grid item xs={12}>
          <a href="https://postgresql.org/"> PostgreSQL</a> for an object-relational database system
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.thenewsapi.com/">TheNewsAPI</a> For breaking news
        </Grid>
        <Grid item xs={12}>
          <a href=" https://aws.amazon.com/">Amazon Web Services</a> S3 For file storage
        </Grid>
        <Grid item xs={12}>
          <a href="https://www.netlify.com/">Netlify</a> For web hosting
        </Grid>
        <Grid item xs={12}>
          <a href="https://render.com/">Render</a> For server hosting
        </Grid>
        <Grid item xs={12}>
          <a href="https://brevo.com/">Brevo</a> For emailing
        </Grid>
        <Grid item xs={12}>
          <a href="https://react.dev/">React</a> For the front-end framework
        </Grid>
        <Grid item xs={12}>
          <a href="https://mui.com/material-ui/">Material UI</a> For design
        </Grid>
        <Grid item xs={12}>
          <a href="https://catfact.ninja/">Catfacts</a> for Cat Facts
        </Grid>
        <Grid item xs={12}>
          <Button onClick={() => navigate('/')}>Go Home</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default InformationPage
