import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

export const UsernameRef = ({ username }) => {
  return (
    <Link to={`/users/${username}`}>
      <Typography>{username}</Typography>
    </Link>
  )
}

export default UsernameRef
