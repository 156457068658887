import React, { useState } from 'react'
import { TextInput } from '../../components/Input'
import { CreateAccount } from '../../services/api'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '../../components/Button'
import { PasswordInput } from '../../components/Input'
import { useSnackbar } from '../../hooks/useSnackbar'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

export default function CreateAccountPage() {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const navigate = useNavigate()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)

  const handleButtonPress = async () => {
    await CreateAccount({
      email,
      password,
      first,
      last,
      username,
    })
      .then(() => {
        snackbar('Account created! Please check your email for a verification code.')
        navigate('/')
      })
      .catch((res) => snackbar(res?.error?.message, 'error'))
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Create Account
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} p={1}>
          <TextInput value={username} onChange={(e) => setUsername(e.target.value)} label="Username" />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <TextInput value={email} onChange={(e) => setEmail(e.target.value)} label="Email" />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <TextInput value={first} onChange={(e) => setFirst(e.target.value)} label="First Name" />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <TextInput value={last} onChange={(e) => setLast(e.target.value)} label="Last Name" />
        </Grid>
        <Grid item xs={12} sm={6} p={1}>
          <PasswordInput
            required
            label="Password"
            id="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Button onClick={handleButtonPress} className={classes.button}>
            Create Account
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
