import { UpdateFollower } from '../../services/api'
import React from 'react'

import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { useSnackbar } from '../../hooks/useSnackbar'
import Follower from './components/Follower'
import { useFollowers } from '../../hooks/useFollowers'

function FollowersPage() {
  const snackbar = useSnackbar()

  const { data: followersQuery, isLoading: followersIsLoading, refetch: queryFollowers } = useFollowers()

  const handleUpdateFollower = (id, position, status) => {
    UpdateFollower({
      id: id,
      accepted: status,
    }).then(() => queryFollowers())
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Followers
      </Typography>
      {followersIsLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container padding={1} spacing={1}>
          <Grid item xs={12} sm={12}>
            <List disablePadding>
              <Grid item container>
                {followersQuery?.followers?.length === 0 ? (
                  <p>None</p>
                ) : (
                  followersQuery?.followers?.map((follower, i) => (
                    <Follower follower={follower} key={follower.userID} i={i} />
                  ))
                )}
              </Grid>
            </List>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default FollowersPage
