import { useQuery } from 'react-query'
import { useSnackbar } from '../hooks/useSnackbar'
import { loadJSONFromState, saveJSONToState } from '../utils'

import * as api from '../services/api'

export function usePhotos({ userID, username }) {
  const snackbar = useSnackbar()

  let key = ['photosData', userID, username]
  const { data, isLoading, isFetching, refetch } = useQuery(key, (params) => api.ListPhotos(userID, username, 0), {
    initialData: () => {
      let dat = loadJSONFromState(key)
      return loadJSONFromState(key)
    },
    onSuccess: (dat) => {
      saveJSONToState(key, dat)
    },
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
  })

  return { data, isLoading, isFloading: isFetching || isLoading, refetch }
}
