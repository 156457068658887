import { useQuery } from 'react-query'
import { useSnackbar } from '../hooks/useSnackbar'
import { loadJSONFromState, saveJSONToState } from '../utils'

import * as api from '../services/api'

export function useUser(username) {
  const snackbar = useSnackbar()

  let key = ['user', username]
  const { data, isLoading, isFetching } = useQuery(key, () => api.GetUser(username), {
    initialData: () => {
      return loadJSONFromState(key)
    },
    onSuccess: (dat) => {
      saveJSONToState(key, dat)
    },
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
  })

  return { data, isLoading, isFloading: isFetching || isLoading }
}
