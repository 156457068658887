import React, { useState } from 'react'
import Button from '../../../components/Button'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Badge from '../../../components/Badge'
import UsernameRef from '../../../components/UsernameRef'
import { GetRepliesForPost, ReplyToPost, DeletePost, DeleteReply } from '../../../services/api'
import { useSnackbar } from '../../../hooks/useSnackbar'
import TextField from '@mui/material/TextField'
import { useAuth } from '../../../services/authcontext'
import Avatar from '../../../components/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'

const Post = ({ post }) => {
  const queryClient = useQueryClient()
  const { authData } = useAuth()
  const snackbar = useSnackbar()
  const [replies, setReplies] = useState()
  const [content, setContent] = useState('')
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    deletePostQ(post.postID)
  }

  const {
    data: repliesQuery,
    isLoading: catsIsLoading,
    refetch: GetRepliesForPostQ,
  } = useQuery(['replies', post.postID], (params) => GetRepliesForPost(params.queryKey[1]), {
    onSuccess: (res) => {
      setReplies(res)
      setContent('')
    },
    onError: (err) => {
      snackbar(err?.error?.message, 'error')
    },
    enabled: false,
  })

  const {
    mutate: ReplyToPostQ,
    isLoading: readNotificationIsLoading,
    error: readError,
  } = useMutation(() => ReplyToPost({ postID: post.postID, content }), {
    onSuccess: () => {
      snackbar('Reply Created successfully!')
      GetRepliesForPostQ()
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })

  const {
    mutate: deletePostQ,
    isLoading: deleteIsLoading,
    error: deleteError,
  } = useMutation((id) => DeletePost(id), {
    onSuccess: () => {
      snackbar('Post deleted successfully!')
      queryClient.invalidateQueries({ queryKey: ['postsData', post.userID] })
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })
  return (
    <Paper elevation={3} sx={{ p: 1 }}>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar alt={`${post.first} ${post.last}`} src={post.thumbnailURL} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography>{post.content}</Typography>
          </Grid>
          {post.userID === authData.user.userID && (
            <Grid item>
              <IconButton
                disableElevation
                disableRipple
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <>
          <UsernameRef username={post.username} /> {post.first} {post.last} {post.created}
        </>
      </Box>

      <Grid item xs={12}>
        {replies ? (
          <>
            <Grid container xs={12} spacing={1}>
              <Grid item xs={12}>
                <TextField value={content} onChange={(e) => setContent(e.target.value)} fullWidth />
              </Grid>
              <Grid item>
                <Button onClick={() => ReplyToPostQ(post.postID, content)}>Send Reply</Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setReplies()
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
            {replies?.replies?.map((reply) => (
              <Paper key={reply.id} elevation={3} m={3}>
                <Reply reply={reply} userID={authData.user.userID} refreshReplies={GetRepliesForPostQ} />
              </Paper>
            ))}
          </>
        ) : (
          <Grid item xs={12}>
            <Badge badgeContent={post.repliesCount}>
              <Button
                onClick={() => {
                  GetRepliesForPostQ(post.postID)
                }}
              >
                Replies
              </Button>
            </Badge>
          </Grid>
        )}
      </Grid>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={(event) => handleDelete(event, 'delete')}>
          <ListItemIcon>
            <DeleteForeverIcon /> Delete
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Paper>
  )
}
const Reply = ({ reply, userID, refreshReplies }) => {
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()
  const [replies, setReplies] = useState()
  const [content, setContent] = useState('')
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    deleteReplyQ(reply.id)
  }

  const {
    mutate: deleteReplyQ,
    isLoading: deleteIsLoading,
    error: deleteError,
  } = useMutation((id) => DeleteReply(id), {
    onSuccess: () => {
      refreshReplies(reply.postID)
      snackbar('Reply deleted successfully!')
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })

  return (
    <Box m={1} sx={{ p: 1 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar alt={`${reply.first} ${reply.last}`} src={reply.thumbnailURL} />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography>{reply.content}</Typography>
        </Grid>
        {reply.userID === userID && (
          <Grid item>
            <IconButton
              disableElevation
              disableRipple
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <>
        <UsernameRef username={reply.username} /> {reply.first} {reply.last} {reply.created}
      </>

      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={(event) => handleDelete(event, 'delete')}>
          <ListItemIcon>
            <DeleteForeverIcon /> Delete
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </Box>
  )
}
export default Post
