import { createContext, useContext, useState, useEffect } from 'react'
import { Login, SetTokenPair } from '../services/api'

const AuthContext = createContext()

export const AuthProvider = (props) => {
  const [authData, setAuthData] = useState() // TODO: Check browser for state.
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    //Every time the App is opened, this provider is rendered
    //and call de loadStorageData function.
    loadStorageData()
    // We're going to purge local storage and force logouts if it gets too big.
    if (calculateStorageDataSize() > 256) {
      console.log(`We have too much data: ${calculateStorageDataSize()}. Logging out.`)
      logout()
    }
  }, [])

  const calculateStorageDataSize = () => {
    var allStrings = ''
    for (var key in window.localStorage) {
      allStrings += window.localStorage[key]
    }
    return allStrings ? 3 + (allStrings.length * 16) / (8 * 1024) : '0'
  }

  const loadStorageData = async () => {
    // actually load stuff
    let dat = localStorage.getItem('userData')
    if (dat != null && dat !== '' && dat !== undefined && dat !== 'undefined') {
      let user = JSON.parse(dat)
      setAuthData(user)
      SetTokenPair(user?.accessToken, user?.refreshToken)
    }
    setLoading(false)
  }

  const login = async (email, password, code) => {
    let user = await Login(email, password, code)
    // Check that there wasn't an error
    localStorage.setItem('userData', JSON.stringify(user))
    setAuthData(user)
    SetTokenPair(user.accessToken, user.refreshToken)
  }

  // setauthdata is a helper to set auth data for the purpose of refreshing thumbnail urls and stuff.
  const setAuthDataFn = (user) => {
    setAuthData(user)
    localStorage.setItem('userData', JSON.stringify(user))
    SetTokenPair(user.accessToken, user.refreshToken)
  }

  // setPhoto is a helper to set profile photo when it times out.
  const setPhoto = (url) => {
    let newAuthData = { ...authData, user: { ...authData.user, thumbnailURL: url } }
    setAuthData(newAuthData)
    localStorage.setItem('userData', JSON.stringify(newAuthData))
  }

  const logout = async () => {
    localStorage.clear()
    localStorage.removeItem('userData')
    SetTokenPair('', '')
    setAuthData()
  }

  return (
    <AuthContext.Provider
      value={{ authData, login, logout, loading, setAuthDataFn, setPhoto }}
      {...props}
    ></AuthContext.Provider>
  )
}

export const useAuth = () => {
  let context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
