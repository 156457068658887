// LoadJSONFromState accepts a key. If it's an array, it joins it with no separator, then fetches that value from state. if it's truthy and not 'undefined', it json parses and returns it.
// otherwise, it returns undefined.
function loadJSONFromState(key) {
  if (Array.isArray(key)) {
    key = key.join('')
  }
  let rawData = localStorage.getItem(key)
  if (rawData != null && rawData !== '' && rawData !== undefined && rawData !== 'undefined') {
    let data = JSON.parse(rawData)
    return data
  }
}

// saveJSONToState accepts a key. If it's an array, it joins it with no separator, then stores the value of data to state under that key
// otherwise, it returns undefined.
function saveJSONToState(key, data) {
  if (Array.isArray(key)) {
    key = key.join('')
  }
  localStorage.setItem(key, JSON.stringify(data))
}

export { loadJSONFromState, saveJSONToState }
