import React, { useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'
import { RequestFollowee, UpdateThumbnail, CreatePost, DeletePost } from '../../services/api'

import PostBox from '../../components/PostBox'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { useAuth } from '../../services/authcontext'
import { useUser } from '../../hooks/useUser'
import { usePosts } from '../../hooks/usePosts'

import { useSnackbar } from '../../hooks/useSnackbar'
import Avatar from '../../components/Avatar'
import { useMutation } from 'react-query'
import Post from './components/Post'
import Stack from '@mui/material/Stack'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  layout: {
    width: 'auto',
    height: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(1200 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}))

function ProfilePage() {
  const navigate = useNavigate()
  const classes = useStyles()
  const [showHome, setShowHome] = useState(false)
  const [showAccessDeniedMessage, setShowAccessDeniedMessage] = useState(false)
  let params = useParams()
  const { authData, setAuthDataFn } = useAuth()
  const snackbar = useSnackbar()
  const [hideAddButton, setHideAddButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef(null)

  const { data: userQuery, isFloading: userIsFloading } = useUser(params?.username)

  // TODO (TDL): THis is working weird. We should use username instead of ID if we are going to keep this pattern.
  const {
    data: postsQuery,
    isLoading: postsIsLoading,
    isFloading: postsIsFloading,
    refetch: ListPostsQ,
  } = usePosts({ username: params.username, followeeID: userQuery?.user?.userID })
  const { mutate: submitPostQ, isLoading: submitIsLoading } = useMutation((postText) => CreatePost(postText), {
    onSuccess: () => {
      snackbar('Post made successfully!')
      ListPostsQ()
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })

  const {
    mutate: deletePostQ,
    isLoading: deleteIsLoading,
    error: deleteError,
  } = useMutation((id) => DeletePost(id), {
    onSuccess: () => {
      snackbar('Post deleted successfully!')
      ListPostsQ()
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })

  // if it's me, show submit box and hide follow button.
  useEffect(() => {
    if (authData.user.userID === userQuery?.user?.userID) {
      setShowHome(true)
    } else {
      setShowHome(false)
    }
  }, [authData, userQuery])

  const LoadThumbnail = (thumbnail) => {
    setIsLoading(true)
    try {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const srcData = fileReader.result
        UpdateThumbnail(srcData) // contains filereader metadata, which is fine, we just need to read it on the front end.
          .then((res) => {
            ;(res?.thumbnailURL && snackbar('Thumbnail updated successfully!')) ||
              setAuthDataFn({
                ...authData,
                user: { ...authData.user, thumbnailURL: res.thumbnailURL },
              }) // This works, but state update should maybe be centralized in a reducer.
            setIsLoading(false)
          })
          .catch((res) => {
            snackbar(`failed to update thumbnail: ${res.error.message}`, 'error')
            setIsLoading(false)
          })
      }
      fileReader.readAsDataURL(thumbnail)
    } catch (error) {
      setIsLoading(false)
      snackbar('Error reading file. Please try again later', 'error')
    }
  }
  return (
    <main className={classes.layout}>
      <Typography variant="h5" gutterBottom>
        Profile
      </Typography>
      {(userIsFloading || postsIsFloading) && (
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={6}>
              <Grid item xs={12} sm={6}>
                <Button
                  disableElevation
                  disableRipple
                  disabled={!showHome || isLoading}
                  onClick={() => inputRef.current.click()}
                >
                  <Avatar
                    alt={`${userQuery?.user?.first} ${userQuery?.user?.last}`}
                    src={userQuery?.user?.thumbnailURL ? userQuery?.user?.thumbnailURL : null}
                    sx={{ width: 128, height: 128 }}
                  />
                </Button>
                <input
                  type="file"
                  name="profileImage"
                  accept="image/*"
                  className="local"
                  ref={inputRef}
                  onChange={(event) => {
                    event.target.files[0] && LoadThumbnail(event.target.files[0])
                  }}
                  hidden
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>{userQuery?.user?.username}</Typography>
              <Typography>{userQuery?.user?.first}</Typography>
              <Typography>{userQuery?.user?.last}</Typography>
              {userQuery?.user?.privateProfile ? (
                <Typography variant="h6">Private</Typography>
              ) : (
                <Typography variant="h6">Public</Typography>
              )}
              {userQuery?.user?.autoApproveFollowers && <Typography variant="h6">Auto Approve</Typography>}
              {userQuery?.followee && !showHome && (
                <Grid item>
                  <Typography variant="h6">{"You're following them."}</Typography>
                </Grid>
              )}
              {(showHome || userQuery?.followee) && (
                <Button onClick={() => navigate(`/users/${params?.username}/photos`)}>Photos</Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {showHome && <PostBox isLoading={submitIsLoading} onPost={(text) => submitPostQ(text)} />}
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} md={6}>
          {!showHome && !userQuery?.followee && !hideAddButton && (
            <Grid item>
              <Button
                onClick={() =>
                  RequestFollowee(userQuery?.user?.userID)
                    .then(() => {
                      snackbar('Request sent!')
                      setHideAddButton(true)
                      // if they're set to auto approve, we can refresh the page to get updated info.
                      if (!userQuery?.user?.autoApproveFollowers) {
                        ListPostsQ()
                      }
                    })
                    .catch((res) => snackbar(res?.error?.message, 'error'))
                }
                variant="contained"
                className={classes.button}
              >
                Request To Follow
              </Button>
            </Grid>
          )}
          <Grid item container xs={12}>
            {!showHome && showAccessDeniedMessage && !postsQuery?.posts?.length > 0 ? (
              <Grid item xs={12}>
                {"You are not allowed to view this person's posts. Are you following them?"}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Stack spacing={2}>
                  {postsQuery?.posts?.map((post, i) => (
                    <Post post={post} key={post.postID} />
                  ))}
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </main>
  )
}

export default ProfilePage
