import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import useMediaQuery from '@mui/material/useMediaQuery'
import Badge from '../components/Badge'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Outlet } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { SearchBar } from './components/SearchBar'
import Avatar from '../components/Avatar'
import { useAuth } from '../services/authcontext'
import HomeIcon from '@mui/icons-material/Home'
import HeadsetIcon from '@mui/icons-material/Headset'
import CampaignIcon from '@mui/icons-material/Campaign'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { useTheme } from '@mui/styles'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import PlagiarismIcon from '@mui/icons-material/Plagiarism'
import InfoIcon from '@mui/icons-material/Info'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { ClearNotification } from '../services/api'
import { useSnackbar } from '../hooks/useSnackbar'
import { useMe } from '../hooks/useMe'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import { useMutation } from 'react-query'
import { useNotifications } from '../hooks/useNotifications'

export default function AuthorizedLayout({ children, ...props }) {
  const theme = useTheme()
  const lessThanSM = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const [drawerLeft, setDrawerLeft] = useState(false)
  const [value, setValue] = React.useState(0)
  const classes = useStyles()
  const { authData, logout, setPhoto } = useAuth()
  const snackbar = useSnackbar()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const menuOpen = Boolean(anchorEl)
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null)
  const profileMenuOpen = Boolean(profileAnchorEl)
  const [avatarRetries, setavatarRetries] = useState(0)
  const [notificationsCount, setNotificationsCount] = useState(0)

  useEffect(() => {
    // log out if you don't have a username saved
    if (!authData.user.username) {
      logout()
    }
  }, [])

  const { data: me, isLoading: meIsLoading, refetch: GetMeQ } = useMe()
  const handleAvatarError = (event) => {
    console.log('Error loading avatar. Retrying')
    if (avatarRetries < 1) {
      setavatarRetries((r) => r++)
      // limit retries in case this breaks so we aren't constantly refreshing or something
      GetMeQ().then((res) => {
        res?.data?.user?.thumbnailURL && setPhoto(res.data.user.thumbnailURL)
      })
    }
    console.log({ avatarRetries })
  }

  const {
    data: notificationsQuery,
    isLoading: notificationsIsLoading,
    refetch: ListNotificationsQ,
  } = useNotifications()

  const {
    mutate: ClearNotificationQ,
    isLoading: readNotificationIsLoading,
    error: readError,
  } = useMutation((id) => ClearNotification(id), {
    onSuccess: () => {
      ListNotificationsQ()
    },
    onError: (error) => {
      snackbar(error?.error?.message, 'error')
    },
  })

  const list = (anchor) => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem>
          <SearchBar />
        </ListItem>
        {[
          { icon: <HomeIcon />, to: '/', label: 'Home' },
          { icon: <BurstModeIcon />, to: `/users/${authData.user.username}/photos`, label: 'Photos' },
          { icon: <HeadsetIcon />, to: '/followers', label: 'Followers' },
          { icon: <CampaignIcon />, to: '/followees', label: 'Followees' },
          { icon: <LibraryBooksIcon />, to: '/posts', label: 'Posts' },
          { icon: <PeopleAltIcon />, to: '/users', label: 'Users' },
          { icon: <PlagiarismIcon />, to: '/privacy_policy', label: 'Privacy Policy' },
          { icon: <InfoIcon />, to: '/information', label: 'Information' },
        ].map((dets) => (
          <ListItem key={dets.label} disablePadding>
            <ListItemButton onClick={() => navigate(dets.to) || setDrawerLeft(false)}>
              <ListItemIcon>{dets.icon}</ListItemIcon>
              <ListItemText primary={dets.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding></ListItem>
      </List>
    </Box>
  )

  // get count of unseen notifications
  useEffect(() => {
    let unseen = notificationsQuery?.notifications?.filter((n) => !n.seen)
    setNotificationsCount(unseen?.length)
  }, [notificationsQuery])

  return (
    <Container maxWidth={false} disableGutters={lessThanSM}>
      <React.Fragment>
        <Drawer anchor={'left'} open={drawerLeft} onClose={() => setDrawerLeft(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setDrawerLeft(true)} edge="start">
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap className={classes.title} color="inherit">
            TylorCorp
          </Typography>
          <Badge badgeContent={notificationsCount}>
            <IconButton
              disableElevation
              disableRipple
              onClick={(event) => setAnchorEl(event.currentTarget)}
              aria-controls={menuOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? 'true' : undefined}
            >
              <NotificationsIcon />
            </IconButton>
          </Badge>
          <Menu id="notification-menu" anchorEl={anchorEl} open={menuOpen} onClose={() => setAnchorEl()}>
            {!notificationsQuery?.notifications && (
              <MenuItem
                onClick={() => {
                  setAnchorEl()
                }}
              >
                You have no notifications!
              </MenuItem>
            )}
            {notificationsQuery?.notifications?.map((n) => (
              <MenuItem
                key={n.id}
                sx={{ backgroundColor: n.seen ? theme.palette.grey['200'] : '' }}
                onClick={() => {
                  ClearNotificationQ(n.id)
                  setAnchorEl()
                  navigate(`/users/${n.username}`)
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {n.username} {n.eventType} you!
                  </Grid>
                  <Grid item xs={12}>
                    {n.created}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Menu>
          <Button
            edge="end"
            disableElevation
            disableRipple
            // onClick={() => navigate(`/users/${authData?.user?.username}`)}
            onClick={(event) => {
              setProfileAnchorEl(event.target)
            }}
          >
            <Avatar
              onError={handleAvatarError}
              alt={`${authData?.user?.first} ${authData?.user?.last}`}
              src={authData?.user?.thumbnailURL}
            />
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth={'lg'} disableGutters={lessThanSM}>
        <Box p={1}>
          <Paper elevation={3} className={classes.paper}>
            <Outlet />
          </Paper>
        </Box>
      </Container>
      <Menu
        id="profile-menu"
        anchorEl={profileAnchorEl}
        open={profileMenuOpen}
        onClose={() => setProfileAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate(`/users/${authData?.user?.username}`) || setProfileAnchorEl(null)}>
          Profile
        </MenuItem>
        <MenuItem onClick={() => navigate(`/settings`) || setProfileAnchorEl(null)}>Settings</MenuItem>
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  layout: {
    width: 'auto',
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(1200 + theme.spacing(2) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    height: '90vh',
    overflow: 'auto',
    overflowX: 'hidden',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up(1200 + theme.spacing(6))]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 12, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))
