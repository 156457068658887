import { useEffect } from 'react'
import LoginPage from './public/LoginPage'
import CreateAccountPage from './public/CreateAccountPage'
import ResetPasswordPage from './public/ResetPasswordPage'

import { useAuth } from '../services/authcontext'
import HomePage from './private/HomePage'
import ProfilePage from './private/ProfilePage'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { AuthorizedLayout, UnauthorizedLayout } from '../layout'
import VerifyPage from './public/VerifyPage'
import SettingsPage from './private/SettingsPage'
import FollowersPage from './private/FollowersPage'
import FolloweesPage from './private/FolloweesPage'
import PostsPage from './private/PostsPage'
import UsersPage from './private/UsersPage'
import PhotosPage from './private/PhotosPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import ContactUsPage from './ContactUsPage'
import InformationPage from './InformationPage'
import { Healthz } from '../services/api'

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UnauthorizedLayout />}>
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        <Route path="/create_account" element={<CreateAccountPage />} />
        <Route path="/information" element={<InformationPage />} />
        <Route path="/verify/:verificationCode" element={<VerifyPage />} />
        <Route path="/reset_password/:verificationCode" element={<ResetPasswordPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
}

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthorizedLayout />}>
        <Route path="/contact_us" element={<ContactUsPage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        <Route path="/followers" element={<FollowersPage />} />
        <Route path="/followees" element={<FolloweesPage />} />
        <Route path="/posts" element={<PostsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/users/:username/photos" element={<PhotosPage />} />

        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/information" element={<InformationPage />} />
        <Route path="/users/:username" element={<ProfilePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
}

export default function Router() {
  const { authData, loading } = useAuth()

  // Fire a request to wake up the server.
  useEffect(() => {
    Healthz()
      .then()
      .catch((err) => console.log(err))
  }, [])

  if (loading) {
    return <div>loading</div>
  }
  if (authData) {
    return (
      <BrowserRouter>
        <PrivateRoutes />
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <PublicRoutes />
    </BrowserRouter>
  )
}
