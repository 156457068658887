// const config = require('./config.js')()

let token = ''
// let refreshToken = ''

export function SetTokenPair(t /*rt*/) {
  token = t
  // refreshToken = rt
}

export async function Login({ email, password }) {
  return _post('users/sign_in', { email, password })
}

// Health isn't usually called by us, but we fire it once whenever someone comes here so the server starts waking up.
export async function Healthz() {
  return _post('healthz')
}

export async function ContactUs(message) {
  return _post('contact_us', { message })
}
export async function CreateAccount({ email, username, first, last, password }) {
  return _post('users/create_account', { email, username, first, last, password })
}

export async function SearchUsers(query) {
  return _post('users/search', { query })
}

export async function RequestPasswordReset(email) {
  return _post('users/request_password_reset', { email })
}

export async function ResetPassword({ verificationCode, newPassword }) {
  return _post('users/reset_password', { verificationCode, newPassword })
}

export async function VerifyAccount(verificationCode) {
  return _post(`users/verify`, { verificationCode })
}

export async function ListNews() {
  return _get('news')
}

export async function ListUsers() {
  return _get('users/list')
}
export async function ListFollowers() {
  return _post('followers/list', {})
}
export async function ListFollowees() {
  return _post('followees/list', {})
}

export async function ListNotifications() {
  return _post('users/notifications/get')
}

export async function ClearNotification(notificationID) {
  return _post('users/notifications/clear', { notificationID })
}
export async function DeleteFollowee(id) {
  return _post('followees/delete', { followeeID: id })
}

export async function DeleteFollower(id) {
  return _post('followers/delete', { followerID: id })
}

export async function UpdateFollower({ id, accepted }) {
  return _post('followers/update', { followerID: id, accepted: accepted })
}

export async function RequestFollowee(followeeID) {
  return _post('followees/request', { followeeID })
}
export async function CreatePost(content) {
  return _post('posts/create', { content })
}
export async function DeletePost(id) {
  return _post('posts/delete', { id })
}
export async function DeleteReply(id) {
  return _post('posts/delete_reply', { id })
}
export async function ListPosts({ followeeID = '', username = '', onlyFollowees = false } = {}) {
  return _post('posts/list', { username, followeeID, onlyFollowees })
}
export async function ListPhotos(userID, username, offset) {
  return _post('photos/list', { userID, username, offset })
}
export async function AddPhoto(photo) {
  return _post('photos/add', { data: photo })
}
export async function DeletePhoto(id) {
  return _post('photos/delete', { photoID: id })
}
export async function GetRepliesForPost(postID) {
  return _post('posts/get_replies', { postID })
}
export async function ReplyToPost({ postID, content }) {
  return _post('posts/reply_to_post', { postID, content })
}
export async function ListBases() {
  return _get('base/list')
}
export async function ListSquads() {
  return _get('squad/list')
}
export async function CreateSquad({ squad_name }) {
  return _post('squad', { squad_name })
}
export async function CreateBase({ latitude, longitude, radius, base_name }) {
  return _post('base', {
    latitude,
    longitude,
    radius,
    base_name,
  })
}

export async function GetMe() {
  return _get('users/me', {})
}

export async function DeleteAccount(password) {
  return _post('users/delete', { password })
}

export async function GetUser(username) {
  return _post('users/get', { username })
}

export async function GetEverything() {
  return _get('user/everything')
}

export async function UpdateThumbnail(thumbnail) {
  return _post('users/update_thumbnail', { thumbnail })
}

export async function EditUser({
  first,
  last,
  password,
  newPassword,
  mfaEnabled,
  privateProfile,
  autoApproveFollowers,
  sendNotificationEmails,
}) {
  return _post('users/update', {
    first,
    last,
    password,
    newPassword,
    mfaEnabled,
    privateProfile,
    autoApproveFollowers,
    sendNotificationEmails,
  })
}

// Check if our token is expired before we send a request.
// If we want, we can request a refresh if it's expired.
// This could avoid generating extra logs from us having to deny people.
//
// function parseJwt(token) {
// if (!token || token === undefined) {
// return
// }
// const claims = JSON.parse(atob(token.split('.')[1]))
//console.log({ claims })
// {claims:{exp: 1698809766,sub:"d519d778-d243-4e80-98c0-30c71c9a338a"}}
// }

export function _get(path, body, opts) {
  const defaultOpts = {
    method: 'GET',
    credentials: 'include',
    ...opts,
  }
  if (token !== '' && token !== null) {
    // parseJwt(token)
    defaultOpts.headers = {
      ...defaultOpts.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return _fetch(apiUrl(path), defaultOpts)
}

export function _post(path, body, opts) {
  const defaultOpts = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
    ...opts,
  }
  if (token !== '' && token !== null) {
    // parseJwt(token)

    defaultOpts.headers = {
      ...defaultOpts.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return _fetch(apiUrl(path), defaultOpts)
}

function _fetch(url, opts) {
  return fetch(url, opts)
    .then((resp) => {
      if (resp.status !== 200) {
        return resp.json().then((data) => {
          throw data
        })
      }
      return resp.json().then((data) => {
        if (data.error) {
          throw data.error
        }
        return data.data
      })
    })
    .catch((e) => {
      // Either an API error was thrown from the success case, or we have a network/request error.
      if (e instanceof Error) {
        // Network or request error. Bundle it in the same format used by API to allow common handling.
        // eslint-disable-next-line no-throw-literal
        throw { ...e, message: e.message || 'Unknown error' }
      } else if (e instanceof Object) {
        // API error. Guarantee error message is set, then throw as-is and let caller handle.
        if (e.message === '') {
          e.message = 'Unknown error'
        }

        throw e
      } else {
        // This shouldn't ever happen, but it means something else was thrown, like a string or a number.
        // eslint-disable-next-line no-throw-literal
        throw { message: e || 'Unknown error' }
      }
    })
}

export async function CatFact() {
  return fetch('https://catfact.ninja/fact')
    .then((resp) => {
      if (resp.status !== 200) {
        return resp.json().then((data) => {
          throw data
        })
      }
      return resp.json().then((data) => {
        if (data.error) {
          throw data.error
        }
        return data
      })
    })
    .catch((e) => {
      // Either an API error was thrown from the success case, or we have a network/request error.
      if (e instanceof Error) {
        // Network or request error. Bundle it in the same format used by API to allow common handling.
        // eslint-disable-next-line no-throw-literal
        throw { ...e, message: e.message || 'Unknown error' }
      } else if (e instanceof Object) {
        // API error. Guarantee error message is set, then throw as-is and let caller handle.
        if (e.message === '') {
          e.message = 'Unknown error'
        }

        throw e
      } else {
        // This shouldn't ever happen, but it means something else was thrown, like a string or a number.
        // eslint-disable-next-line no-throw-literal
        throw { message: e || 'Unknown error' }
      }
    })
}

function apiUrl(path) {
  return process.env.REACT_APP_BACKEND_URL + path
}
